import React from "react";

export default ({ photo, name, testimonial, title }) => {
    return (
        <div class="card">
            <div class="card-content">
                <div class="media">
                    <div class="media-left">
                        <figure class="image is-48x48">
                        <img class="is-rounded" src={photo} alt={name} />
                        </figure>
                    </div>
                    <div class="media-content">
                        <p class="title is-4">{name}</p>
                        <p class="subtitle is-6">{title}</p>
                    </div>
                </div>
                
                <div class="content">
                    {testimonial}
                </div>
            </div>
        </div>
)};
        