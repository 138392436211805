import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
// import ComingSoon from '../media/coming-soon.png';

const Modal = ({ isActive, close, emailTemplateId, success, failure }) => {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [jobTitle, setJobTitle] = React.useState('');
  const [firstNameMissing, setFirstNameMissing] = React.useState(false);
  const [lastNameMissing, setLastNameMissing] = React.useState(false);
  const [emailMissing, setEmailMissing] = React.useState(false);
  const [companyNameMissing, setCompanyNameMissing] = React.useState(false);
  const [jobTitleMissing, setJobTitleMissing] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const validateForm = () => {
    let valid = true;
    if (firstName.trim() === '') {
      setFirstNameMissing(true);
      valid = false;
    }
    if (lastName.trim() === '') {
      setLastNameMissing(true);
      valid = false;
    }
    if (email.trim() === '') {
      setEmailMissing(true);
      valid = false;
    }
    if (companyName.trim() === '') {
      setCompanyNameMissing(true);
      valid = false;
    }
    if (jobTitle.trim() === '') {
      setJobTitleMissing(true);
      valid = false;
    }
    return valid;
  };

  const send = async () => {
    setIsLoading(true);
    const resp = await fetch(`/.netlify/functions/send-case-study?firstName=${firstName}&lastName=${lastName}&email=${email}&companyName=${companyName}&jobTitle=${jobTitle}&templateId=${emailTemplateId}`);
    if (resp.status === 200) {
      success();
    } else {
      failure();
    }
    setIsLoading(false);
    close();
  };

  return <div class={classNames('modal', { 'is-active': isActive })}>
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Recevez l'étude de cas par email</p>
        <button class="delete" aria-label="close" onClick={e => {
          e.stopPropagation();
          close();
        }}></button>
      </header>
      <section class="modal-card-body">

        <div class="field">
          <label class="label">Prénom</label>
          <div class="control">
            <input class={classNames('input', { 'is-danger': firstNameMissing })} type="text" placeholder="Prénom"
            value={firstName} onChange={e => {
              setFirstNameMissing(false);
              setFirstName(e.target.value);
            }} />
          </div>
          {firstNameMissing && <p class="help is-danger">Champ requis</p>}
        </div>

        <div class="field">
          <label class="label">Nom</label>
          <div class="control">
            <input class={classNames('input', { 'is-danger': lastNameMissing })} type="text" placeholder="Nom"
            value={lastName} onChange={e => {
              setLastNameMissing(false);
              setLastName(e.target.value);
            }} />
          </div>
          {lastNameMissing && <p class="help is-danger">Champ requis</p>}
        </div>

        <div class="field">
          <label class="label">Adresse email</label>
          <div class="control">
            <input class={classNames('input', { 'is-danger': emailMissing })} type="text" placeholder="impact@medical.com"
            value={email} onChange={e => {
              setEmailMissing(false);
              setEmail(e.target.value);
            }} />
          </div>
          {emailMissing && <p class="help is-danger">Champ requis</p>}
        </div>

        <div class="field">
          <label class="label">Organisation</label>
          <div class="control">
            <input class={classNames('input', { 'is-danger': companyNameMissing })} type="text" placeholder="Nom de l'entreprise ou de l'établissement de soin"
            value={companyName} onChange={e => {
              setCompanyNameMissing(false);
              setCompanyName(e.target.value);
            }} />
          </div>
          {companyNameMissing && <p class="help is-danger">Champ requis</p>}
        </div>

        <div class="field">
          <label class="label">Rôle</label>
          <div class="control">
            <input class={classNames('input', { 'is-danger': jobTitleMissing })} type="text" placeholder="Chief Data Officer"
            value={jobTitle} onChange={e => {
              setJobTitleMissing(false);
              setJobTitle(e.target.value);
            }} />
          </div>
          {jobTitleMissing && <p class="help is-danger">Champ requis</p>}
        </div>

        <div class="content is-small">
          <p>
           Akimed peut utiliser les informations de contact que vous nous fournissez pour vous mettre à jour 
           sur nos produits et services. Vous pouvez vous désinscrire de nos communications à tout moment. 
           Pour plus d'informations sur la manière dont nous traitons vos données et protégeons 
           votre vie privée, consultez notre <a href="/mentions">politique de confidentialité</a>.
          </p>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class={classNames('button is-danger', { 'is-loading': isLoading })} onClick={e => {
          e.stopPropagation();
          if (validateForm()) {
            send(firstName, lastName, email, companyName, jobTitle);
          }
        }}>Recevoir l'étude de cas</button>
      </footer>
    </div>
  </div>;
};

const Success = ({ active }) => {
  return <div style={{ position: 'fixed', top: '1rem', right: '1rem', zIndex: 2000 }} className={classNames('portal-message-container', { 'is-active': active })}>
    <article class="message is-success">
      <div class="message-body">
        L'étude de cas vous a été envoyé par email avec succès !
      </div>
    </article>
  </div>
};

const Failure = ({ active }) => {
  return <div style={{ position: 'fixed', top: '1rem', right: '1rem', zIndex: 2000 }} className={classNames('portal-message-container', { 'is-active': active })}>
    <article class="message is-danger">
      <div class="message-body">
        L'étude de cas n'a pas pu être envoyé. Veuillez réessayer ultérieurement.
      </div>
    </article>
  </div>
};

export default ({ client, title, thumbnail, emailTemplateId }) => {
  const [modalIsActive, setModalIsActive] = React.useState(false);
  const [successActive, setSuccessActive] = React.useState(false);
  const [failureActive, setFailureActive] = React.useState(false);

  return <a href="#" onClick={() => setModalIsActive(true)} style={{ height: '100%' }}>
    <div class="card" style={{ height: '100%' }}>
      <div class="card-image">
        <figure class="image is-4by3">
          <img src={thumbnail} alt={title} />
        </figure>
      </div>
      <div class="card-content">
        <div class="content">
          <p class="title is-4">Étude de cas</p>
          <p class="subtitle is-6">{client}</p>
          <p>{title}</p>
        </div>
      </div>
    </div>
    {typeof document !== 'undefined' && ReactDOM.createPortal(<Modal
      isActive={modalIsActive}
      close={() => setModalIsActive(false)}
      emailTemplateId={emailTemplateId}
      success={() => {
        setSuccessActive(true);
        setTimeout(() => setSuccessActive(false), 2000);
      }}
      failure={() => {
        setFailureActive(true);
        setTimeout(() => setFailureActive(false), 2000);
      }}
    />, document.body)}
    {typeof document !== 'undefined' && ReactDOM.createPortal(<Success active={successActive} />, document.body)}
    {typeof document !== 'undefined' && ReactDOM.createPortal(<Failure active={failureActive} />, document.body)}
  </a>;
};
