import React from "react";
import { FaQuoteLeft } from 'react-icons/fa';
import { IconContext } from "react-icons";
import Page from "../layouts/Page";
// import _ from "lodash";
import CaseStudy from '../components/CaseStudy';
import HeroHealthCare from '../media/hero/hero-healthcare.png';
import Icon from '../components/Icon';
import LogoPartner from '../components/LogoPartner';
import Testimonial from '../components/Testimonial';
import IllustrationInterp from '../media/illustration/New.png';
// import IllustrationDoctor from '../media/illustration/Doctor.png';
import IllustrationVisual from '../media/illustration/Visual.png';
import IllustrationPair from '../media/illustration/Pair.png';
import AphpLogo from '../media/partners/aphp-logo.jpeg';
import ResilienceLogo from '../media/partners/resilience.png';
import CodocLogo from '../media/partners/codoc.png';
import HyperedgeLogo from '../media/partners/hyperedge.png';
import IpsenLogo from '../media/partners/ipsen.png';
import ResaamLogo from '../media/partners/resaam.png';
import TechHealLogo from '../media/partners/tech2heal.png';
import PixaidLogo from '../media/partners/pixaid.png';
import OrdreLogo from '../media/partners/ordre.png';
import UrgencesLogo from '../media/partners/urgenceschrono.png';
import WithingsLogo from '../media/partners/withings.png';
import PaPhoto from '../media/partners/pa-photo.jpg';

import aphpCaseStudyThumbnail from '../media/case-study/APHP.png';
import myRoboticsCaseStudyThumbnail from '../media/case-study/MyRobotics.png';

const HERO_IMAGE_WIDTH_PX = 400;

function TabContent(props) {
  const activeTab = props.activeTab;
  return (
    <div>
      {activeTab === 'tab-1' &&
        <div class="content">
          <p class="subtitle" style={{ lineHeight: '2.3rem' }} >
            Développer une technologie dans la santé, c’est faire face à une pression constante pour
            respecter la cadence de sortie de nouvelles versions tout en assurant un équilibre des coûts,
            des ressources et de la maintenance. Le marché évoluant rapidement, les organisations doivent
            continuellement innover pour suivre le rythme.
          </p>
          <a class="button is-danger is-size-5" href="/projets-data-ia-sante/">En savoir plus</a>
        </div>
      }
      {activeTab === 'tab-2' &&
        <div class="content">
        <p class="subtitle" style={{ lineHeight: '2.3rem' }} >
          Notre équipe a développé de nombreux outils permettant d'accélérer la mise sur le marché
          d'applications destinées aux patients ou aux professionels de santé.
        </p>
        <a class="button is-danger is-size-5" href="/app-development-sante/">En savoir plus</a>
      </div>
      }
      {activeTab === 'tab-3' &&
        <div class="content">
        <p class="subtitle" style={{ lineHeight: '2.3rem' }} >
          Travailler dans la technologie en santé c’est devoir appréhender deux mondes :
          le monde médical avec une ontologie particulièrement
          complexe et le monde technologique avec son lot de concepts qui évoluent très rapidement.
        </p>
        <a class="button is-danger is-size-5" href="/data-transformation/">En savoir plus</a>
      </div>
      }
      {activeTab === 'tab-4' &&
        <div class="content">
        <p class="subtitle" style={{ lineHeight: '2.3rem' }} >
          Toute révolution se prépare et être prêt à travailler différemment demande beaucoup d’engagement.
          La révolution qui a démarré en santé va avoir un impact important sur la façon de traiter les patients
          et aborder les problèmes de santé publiques de demain.
        </p>
        <a class="button is-danger is-size-5" href="/conseil-innovation/">En savoir plus</a>
      </div>
      }
    </div>
  );
}


export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      layout: (typeof window !== "undefined" && window.LAYOUT) || "grid",
      activeTab: "tab-1",
      title: "Akimed - un partenaire pour innover en santé",
      description: "Akimed accélère vos projets digitaux centrés sur le patients en capitalisant sur une forte expertise technique dans le développement d'applications dans la santé.",
    };
  }

  render() {
    return (
      <Page title={this.state.title} description={this.state.description} >
        <div class="container">
        <section className="hero first-hero is-primary" style={{borderRadius: '25px', marginTop: '150px'}}>
          <div className="hero-body">
            <div className="container">
              <div class="columns is-vcentered">
                <div class="column">
                  <h1 className="title">
                    Votre partenaire pour innover en santé.
                  </h1>
                  <h2 className="subtitle">
                    Nous accélérons vos <strong>programmes digitaux</strong> centrés sur le
                    patient en capitalisant sur notre expertise technique dans le <strong>développement</strong> d'applications en santé et sur nos solutions de <strong>conseil</strong>.
                  </h2>
                </div>
                <div class="column">
                  <div style={{ float: 'right', marginRight: '0.75rem' }}>
                    <div style={{width: `${HERO_IMAGE_WIDTH_PX - 1}px`, height: '313px', overflow: 'hidden' }}>
                      <img
                        src={HeroHealthCare}
                        style={{ maxWidth: `${HERO_IMAGE_WIDTH_PX}px` }}
                        alt="Innover en santé"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div class="columns">
            <div class="column is-6">
              <h1 className="title">Améliorer votre intéropérabilité</h1>
              <div class="content">
              <p class="is-size-5">Nous veillons à ce que les systèmes d'information en santé travaillent ensemble à l'intérieur et au-delà des frontières organisationnelles pour améliorer la prise en charge des patients :</p>
              <ul class="is-size-5">
                <li>Mettez en place l'INS, le DMP, MS Santé et Pro Santé Connect</li>
                <li>Réduisez les coûts et le temps de développement</li>
                <li>Tirez parti des concepts et de l'ontologie existants</li>
                <li>Accélérez la mise sur le marché de votre dispositif médical connecté</li>
              </ul>
            </div>
            </div>
            <div class="column is-4 is-offset-1 is-hidden-mobile">
              <figure class="image">
                <Icon src={IllustrationInterp} />
              </figure>
            </div>
          </div>
          <div class="columns" style={{marginTop: '70px'}}>
            <div class="column is-4 is-offset-1 is-hidden-mobile">
              <figure class="image">
                <Icon src={IllustrationPair} />
              </figure>
            </div>
            <div class="column is-6 is-offset-1">
              <h1 className="title">Valoriser vos données de santé</h1>
              <div class="content">
                <p class="is-size-5">Mettez en place des plateformes de données robustes, standardisez la donnée de santé pour réaliser des études observationnelles reproductibles et accélérer la mise sur le marché de nouveaux produits ou services :</p>
                <ul class="is-size-5">
                  <li>Tirez parti terminologies standards comme LOINC, SNOMED ou RxNorm</li>
                  <li>Structurez vos données en OMOP ou FHIR</li>
                  <li>Générez des preuves à partir de données de vie réelle (real world evidences)</li>
                  <li>Utilisez la puissance du cloud pour analyser vos données génomiques</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="columns" style={{marginTop: '70px'}}>
            <div class="column is-6">
              <h1 className="title">Accélérer votre transformation digitale</h1>
              <div class="content">
              <p class="is-size-5">Accélérer la mise en place de vos initiatives d’innovation de la stratégie de transformation digitale à la numérisation des processus métiers :</p>
              <ul class="is-size-5">
                <li>Développez des applications pour les professionnels de santé et les patients</li>
                <li>Créez les digital therapeutics (DTx) de demain</li>
                <li>Automatisez vos pipelines data</li>
                <li>Construisez des dashboards dynamiques</li>
                <li>Déployez votre <em>digital health platform</em></li>
              </ul>
            </div>
            </div>
            <div class="column is-4 is-offset-1 is-hidden-mobile">
              <figure class="image">
                <Icon src={IllustrationVisual} />
              </figure>
            </div>
          </div>
        </section>
      </div>
      <div>
        <section className="section alt-section">
          <div className="container">
          
                <h1 className="title">Expertise et accompagnement</h1>
                <div class="tabs is-medium">
                  <ul>
                    <li
                      class={ this.state.activeTab === 'tab-1' ? 'is-active':'' }
                      onClick={() => {this.setState({ activeTab: "tab-1"});}}
                    >
                      <a>Accélération de projets<br />digitaux, data et IA</a>
                    </li>
                    <li
                      class={ this.state.activeTab === 'tab-2' ? 'is-active':'' }
                      onClick={() => {this.setState({ activeTab: "tab-2"});}}
                    >
                      <a>Développement Web <br />& Mobile personnalisé</a>
                    </li>
                    <li
                      class={ this.state.activeTab === 'tab-3' ? 'is-active':'' }
                      onClick={() => {this.setState({ activeTab: "tab-3"});}}
                    >
                      <a>Transformation <br />des données de santé</a>
                    </li>
                    <li
                      class={ this.state.activeTab === 'tab-4' ? 'is-active':'' }
                      onClick={() => {this.setState({ activeTab: "tab-4"});}}
                    >
                      <a>Conseil en innovation <br />& gestion des technologies</a>
                    </li>
                  </ul>
                </div>
                <div class="columns">
                  <div class="column is-three-quarters">
                    <TabContent activeTab={this.state.activeTab} />
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-3" style={{ display: 'flex' }}>
                    <CaseStudy
                      client="L'Entrepôt de Données de Santé de l'AP-HP"
                      title="Accélérer le développement de Cohort360 avec des données synthétiques."
                      emailTemplateId="email_case_study_aphp"
                      thumbnail={aphpCaseStudyThumbnail} />
                  </div>
                  <div class="column is-3" style={{ display: 'flex' }}>
                    <CaseStudy
                      client="MyRobotics"
                      title="Conception et implémentation du backend FHIR de l’application MyGus."
                      emailTemplateId="email_case_study_myrobotics"
                      thumbnail={myRoboticsCaseStudyThumbnail} />
                  </div>
                </div>
              </div>
        </section>
        <section class="section">
          <div class="container" style={{maxWidth: "1280px", marginTop: "50px", marginBottom: "50px"}}>
            <h1 class="title is-size-1 has-text-centered">Akimed Connect</h1>
            <h2 class="subtitle is-size-4 has-text-centered">Nous avons développé un ensemble d'API faciles à mettre en place et qui respectent les standards d'intéropérabilité.</h2>
              <div class="columns has-text-centered" style={{marginTop: "40px"}}>
                <div class="column is-5 is-offset-1">
                  <div class="box">
                    <h2 class="title">INS</h2>
                    <p class="block">L’utilisation de l’Identité National de Santé pour devenu incontournable pour <b>sécusiser le référencement</b> et <b>faciliter l'échange et le partage</b> des données de santé.</p>
                    <a class="button is-danger is-link is-rounded block" href="/connecteurs/ins">+</a>
                  </div>
                </div>
                <div class="column is-5">
                  <div class="box">
                    <h2 class="title">DMP</h2>
                    <p class="block">Une connexion au Dossier Médical Partagé permet d'alimenter et de consulter de manière sécurisé les données des patients (allergies, traitments, procédures, etc.).</p>
                    <a class="button is-danger is-link is-rounded block" href="/connecteurs/dmp">+</a>
                  </div>
                </div>
                </div>
                <div class="columns has-text-centered">
                <div class="column is-5 is-offset-1">
                  <div class="box">
                    <h2 class="title">MS Santé</h2>
                    <p class="block">La messagerie sécurité santé permet aux professionnels de santé de communiquer entre eux et bientôt avec les patients également directement dans l'Espace Numérique de Santé.</p>
                    <a class="button is-danger is-link is-rounded block" href="/connecteurs/mssante">+</a>
                  </div>
                </div>
                <div class="column is-5">
                  <div class="box">
                    <h2 class="title">Pro Santé Connect</h2>
                    <p class="block">Pro Santé Connect permet aux professionnels de s’authentifier soit avec une e-CPS (application mobile) soit avec une carte CPS physique à tous les services numériques de santé raccordés.</p>
                    <a class="button is-danger is-link is-rounded block" href="/connecteurs/prosanteconnect">+</a>
                  </div>
                </div>
              </div>
          </div>
        </section>
        <section class="alt-section">
          <div className="container">
            <h1 className="title has-text-centered">Nos partenaires et clients qui nous font confiance</h1>
            <div class="columns" style={{marginTop: "50px", marginBottom: "50px"}}>
              <div class="column is-7">
                <div class="columns is-centered is-mobile">
                    <LogoPartner image={ResilienceLogo} alt="Resilience" />
                    <LogoPartner image={IpsenLogo} alt="IPSEN" />
                    <LogoPartner image={AphpLogo} alt="APHP" />
                </div>
                <div class="columns is-centered is-mobile">
                    <LogoPartner image={CodocLogo} alt="Codoc" />
                    <LogoPartner image={HyperedgeLogo} alt="Hyperedge" />
                    <LogoPartner image={PixaidLogo} alt="Pixaid" />
                </div>
                <div class="columns is-centered is-mobile">
                    <LogoPartner image={WithingsLogo} alt="Withings" />
                    <LogoPartner image={ResaamLogo} alt="Resaam" />
                    <LogoPartner image={TechHealLogo} alt="Tech 2 Heal" />
                </div>
                <div class="columns is-centered is-mobile">
                    <LogoPartner image={OrdreLogo} alt="Ordre des infirmiers" />
                    <LogoPartner image={UrgencesLogo} alt="Urgences Chrono / Focus Santé" />
                </div>
              </div>
              <div class="column is-4">
              <Testimonial
                photo={PaPhoto}
                name="Pierre Antoine Bastian"
                title="CEO @ My Robotics"
                testimonial="En seulement quelques semaines, la flying team d'Akimed a développé un entrepôt de données de santé et nos applications web & mobiles étaient devenues compatibles avec les standards d'interopérabilité et lancées sur un hébergeur de données de santé. Nous sommes en bonne route pour la certification de notre dispositif médical connecté." />
              </div>
            </div>
          </div>
        </section>
        </div>
      </Page>
    );
  }
}
